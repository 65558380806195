import { Popover } from 'antd';
import {
  Avatar,
  AvatarShape,
  Column,
  FileImageOutlined,
  Icon,
  Row,
  Space,
  Text,
  Tooltip,
  TooltipPlacement,
} from 'farmhub-ui-core';
import * as React from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import { EditableTagGroup, NameText, TinyText } from 'components';
import { ConnectionIcon } from 'components/Icon';
import { theme } from 'styled';
import { IMAGETYPE, SIZE } from 'utils/constants';
import { formatImageUrl, getEntityInfo } from 'utils/function';

type ownerType = {
  avatar: string;
  name: string;
};

interface InfoCardProps {
  id?: string;
  name: string | React.ReactNode;
  img: string | React.ReactNode;
  info?: string | React.ReactNode;
  entityType?: number;
  link?: string;
  redirectLink?: string;
  width?: number | string;
  tags?: Array<string>;
  editAbleTag?: boolean;
  imageType?: 'PRODUCT' | 'CATEGORY';
  owner?: ownerType;
  showConnectionProduct?: boolean;
  connectionProduct?: any;
  avatarCircle?: boolean;
  size?: SIZE;
}

const InfoCard = ({
  id,
  name,
  img,
  entityType,
  info,
  link,
  redirectLink,
  width,
  tags,
  editAbleTag,
  imageType,
  owner,
  showConnectionProduct,
  connectionProduct,
  avatarCircle = false,
  size = SIZE.DEFAULT,
}: InfoCardProps): JSX.Element => {
  const history = useHistory();
  const handleRedirect = (): void => {
    const win: any = window.open(redirectLink, '_blank');
    win.focus();
  };

  const content = (
    <div>
      <Row>
        <ImgWrapper>
          <Img
            entityType={entityType}
            objectFit="cover"
            alt="img"
            src={formatImageUrl(
              connectionProduct?.avatar,
              IMAGETYPE.AVATAR.xxs
            )}
          />
        </ImgWrapper>
        <DivInfo>
          <Column>
            <Row alignItems="flex-start">
              <Text
                lineHeight="22px"
                title={connectionProduct?.name}
                lineNumber={1}
              >
                {connectionProduct?.name}
              </Text>
              <Space width={6} />
            </Row>
            <TinyText lineNumber={2} color="main">
              {connectionProduct?.code}
            </TinyText>
          </Column>
        </DivInfo>
      </Row>
    </div>
  );

  const GenImage = (): any => {
    if (img) {
      if (typeof img === 'object') {
        return img;
      }
      return (
        <ImgWrapper>
          {avatarCircle ? (
            <Avatar
              size={size === SIZE.SMALL ? 48 : 52}
              src={formatImageUrl(String(img), IMAGETYPE.AVATAR.xxs)}
              shape={AvatarShape.Circle}
            />
          ) : (
            <Img
              size={size}
              entityType={entityType}
              objectFit={imageType === 'CATEGORY' ? 'contain' : 'cover'}
              alt="img"
              src={formatImageUrl(String(img), IMAGETYPE.AVATAR.xxs)}
            />
          )}
          {entityType !== undefined && (
            <Tooltip
              placement={TooltipPlacement.Top}
              title={getEntityInfo(entityType).name}
            >
              <Icon width="24px" height="24px" styles={EntityTypeIconStyles}>
                {getEntityInfo(entityType).icon}
              </Icon>
            </Tooltip>
          )}
          {owner && (
            <Avatar
              size={24}
              src={formatImageUrl(owner.avatar, IMAGETYPE.AVATAR.xxs)}
              styles={AvatarStyles}
            />
          )}
        </ImgWrapper>
      );
    }
    return (
      <FakeImg>
        {' '}
        <FileImageOutlined
          style={{ fontSize: '30px', color: '#8c8c8c' }}
        />{' '}
      </FakeImg>
    );
  };

  const GenName = (): any => {
    if (name) {
      if (typeof name === 'object') {
        return name;
      }
      return (
        <Text
          weight="bold"
          lineHeight="22px"
          title={name.toString()}
          lineNumber={1}
        >
          {name}
        </Text>
      );
    }
    return (
      <Text weight="bold" lineHeight="22px" title="Trống" lineNumber={1}>
        Trống
      </Text>
    );
  };

  const GenInfo = (): any => {
    if (info) {
      if (typeof info === 'object') {
        return info;
      }
      return (
        <TinyText lineNumber={2} color="main">
          {info}
        </TinyText>
      );
    }
    return null;
  };

  return (
    <Wrapper
      width={width}
      onClick={() =>
        (link && history.push(link)) || (redirectLink && handleRedirect())
      }
    >
      {GenImage()}
      <Space width={12} />
      <Column styles={{ minHeight: 42 }}>
        <Row alignItems="center">
          {GenName()}
          {showConnectionProduct && (
            <>
              <Space width={6} />
              <Popover content={content} title="Sản phẩm liên kết">
                <div style={{ flexShrink: 0, width: 18, height: 18 }}>
                  <ConnectionIcon
                    width={18}
                    height={18}
                    color={theme.colors.primary.main}
                  />
                </div>
              </Popover>
            </>
          )}
        </Row>

        {owner && (
          <NameText lineNumber={2} color="green">
            {owner.name}
          </NameText>
        )}
        {GenInfo()}
        {tags && (
          <EditableTagGroup editAble={editAbleTag} id={id} tags={tags} />
        )}
      </Column>
    </Wrapper>
  );
};

export default InfoCard;

export const Wrapper = styled.div<{ width?: number | string }>`
  display: flex;
  align-items: center;
  width: ${props =>
    typeof props.width === 'string' ? props.width : `${props.width || 240}px`};
  height: auto;
  cursor: pointer;
  flex-shrink: 0;
`;
const ImgWrapper = styled.div`
  position: relative;
`;

const Img = styled.img<{
  entityType?: number;
  objectFit?: string;
  size?: SIZE;
}>`
  width: ${p => (p.size === SIZE.SMALL ? '48px' : '52px')};
  height: ${p => (p.size === SIZE.SMALL ? '48px' : '52px')};
  border-radius: ${p => (p.entityType != undefined ? '50%' : '6px')};
  flex-shrink: 0;
  object-fit: ${p => p.objectFit || 'cover'};
`;

const EntityTypeIconStyles = css`
  background: #fafafa;
  border-radius: 50%;
  border: 1px solid ${theme.colors.primary.main};
  position: absolute;
  right: 0px;
  bottom: 8px;
  transform: translate(30%, 30%);
`;

const AvatarStyles = css`
  position: absolute;
  right: 0px;
  bottom: 7px;
  transform: translate(30%, 30%);
`;

const DivInfo = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  margin-left: 12px;
`;

const FakeImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #f6fcf9;
  border-radius: 6px;
`;
