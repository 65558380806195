// #region Global Imports
import { DefaultTheme } from 'styled-components';
// #endregion Global Imports

const theme: DefaultTheme = {
  /**
   * Font
   */
  app: {
    padding: {
      vertical: '12px',
      horizontal: '18px',
    },
    icon: {
      size: 18,
      color: '#262626',
    },
  },
  button: {
    height: {
      xs: '32px',
      sm: '36px',
      md: '42px',
      lg: '48px',
    },
    icon: {
      xs: 32,
      sm: 36,
      md: 42,
      lg: 48,
    },
  },
  datePicker: {
    height: {
      xs: '32px',
      sm: '36px',
      md: '42px',
      lg: '48px',
    },
  },
  select: {
    height: {
      xs: '32px',
      sm: '36px',
      md: '42px',
      lg: '48px',
    },
  },
  input: {
    height: {
      xs: '32px',
      sm: '36px',
      md: '42px',
      lg: '48px',
    },
  },
  modal: {
    width: {
      xxs: '420px',
      xs: '560px',
      sm: '600px',
      md: '840px',
      lg: '1200px',
      xl: '1440px',
    },
  },
  tab: {
    padding: {
      paddingVertical: '12px',
      paddingHorizontal: '36px',
    },
  },
  avatar: {
    size: {
      xs: 36,
      sm: 42,
      md: 48,
      lg: 52,
    },
  },
  font: {
    // family: `'Work Sans', sans-serif`,
    family: `'Quicksand', sans-serif`,
    weight: {
      light: '300',
      normal: '400',
      regular: '500',
      medium: '500',
      bold: '600',
      extraBold: '700',
    },
    size: {
      small: '9px',
      tiny: '11px',
      xxs: '12px',
      xs: '13px',
      sm: '16px',
      md: '18px',
      lg: '24px',
      xl: '34px',
    },
  },

  /**
   * Colors
   */
  colors: {
    black: '#000000',
    white: '#fff',
    success: '#39B54A',
    body: '#F2F3F7',
    price: '#EB403D',
    startFill: '#F8961E',

    primary: {
      light: '#39B54A',
      main: '#39B54A',
      dark: '#005333',
      grey: '#BDBDBD',
      contrastText: '#fff',
    },

    secondary: {
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff',
    },

    icon: {
      primary: '#595959',
      secondary: '#6C6C6C',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      lightGreen: '#7CB305',
      green: '#39B54A',
      red: '#EB403D',
      price: '#EB403D',
      link: '#1890FF',
      lightOrange: '#F8961E',
      orange: '#FF6347',
      white: '#fff',
      main: '#39B54A',
      dark: '#17431D',
      black: 'black',
    },

    text: {
      primary: '#595959',
      secondary: '#6C6C6C',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      lightGreen: '#7CB305',
      green: '#39B54A',
      red: '#EB403D',
      price: '#EB403D',
      link: '#1890FF',
      lightOrange: '#F8961E',
      orange: '#FF6347',
      white: '#fff',
      main: '#39B54A',
      dark: '#17431D',
      black: 'black',
    },

    border: {
      light: '#f1f1f1',
      main: '#d9d9d9',
      dark: '#bdbdbd',
      green: '#239047',
    },

    skeleton: {
      background: '#f3f3f3',
      foreground: '#ecebeb',
    },

    navbar: {
      main: 'rgba(57, 181, 74, 0.05)',
      light: 'rgba(57, 181, 74, 0.05)',
    },

    dropdown: {
      main: 'rgba(42, 193, 126, 0.1)',
    },

    error: {
      light: '#e57373',
      main: '#f5222d',
      dark: '#d32f2f',
      contrastText: '#fff',
    },

    warning: '#FFB818',

    info: '#4169E1',

    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
  },

  /**
   * Shadows
   */
  shadows: {
    sm: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    md: 'rgba(0, 0, 0, 0.3) 0px 1px 8px 0px',
    lg: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    xl: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  },

  /**
   * Breakpoints
   */
  screen: {
    xs: '540px',
    sm: '640px',
    md: '1007px',
    lg: '1100px',
    xl: '1230px',
  },

  /**
   * Spacing
   */
  spacing: {
    none: '0px',
    xxs: '6px',
    xs: '12px',
    sm: '18px',
    md: '24px',
    lg: '36px',
    xl: '48px',
  },

  /**
   * Border radius
   */
  radius: {
    sm: '3px',
    md: '6px',
    lg: '12px',
  },

  /**
   * z-index
   */
  zIndex: {
    xs: 10,
    sm: 20,
    md: 30,
    lg: 40,
    xl: 50,
  },

  /**
   * Transition
   */
  transition: {
    duration: '0.5s',
  },
};

export { theme };
