import { arrayMoveImmutable } from 'array-move';
import { MenuOutlined } from 'farmhub-ui-core';
import * as React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import * as S from './styles';

export enum SwitchSize {
  Default = 'default',
  Small = 'small',
}

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

export const DragHandle = SortableHandle(
  ({ disabled = false }: { disabled?: boolean }) => (
    <MenuOutlined
      style={{
        cursor: disabled ? 'not-allowed' : 'grab',
        color: '#999',
      }}
    />
  )
);

const Table = ({
  switchSize = SwitchSize.Default,
  pagination,
  columns,
  dataSource,
  typeSelection,
  onRow,
  draggable,
  callbackOnSortEnd,
  callbackOnUpdateBeforeSortStart,
  id,
  showCheckAll = true,
  showHeader = true,
  ...props
}: TableProps): JSX.Element => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      if (callbackOnSortEnd) {
        callbackOnSortEnd(newData);
      }
    }
  };

  const onUpdateBeforeSortStart = () => {
    if (callbackOnUpdateBeforeSortStart) {
      callbackOnUpdateBeforeSortStart();
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      updateBeforeSortStart={onUpdateBeforeSortStart}
      {...(draggable && {
        components: {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        },
      })}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <S.Table
      showHeader={showHeader}
      typeSelection={typeSelection}
      columns={columns}
      dataSource={dataSource}
      switchSize={switchSize}
      pagination={pagination}
      id={id}
      showCheckAll={showCheckAll}
      {...(draggable && {
        components: {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        },
      })}
      {...props}
    />
  );
};

export default Table;

export interface TableProps {
  showHeader?: boolean;
  typeSelection?: string;
  loading?: boolean;
  switchSize?: SwitchSize;
  styles?: any;
  columns: any;
  dataSource: any;
  pagination?: any;
  bordered?: boolean;
  rowSelection?: any;
  components?: TableComponents;
  columnWidth?: string | number;
  expandable?: any;
  locale?: any;
  sticky?: boolean;
  id?: string;
  scroll?: {
    scrollToFirstRowOnChange?: boolean;
    x?: string | number | true;
    y?: string | number;
  };
  onRow?: (e: any, index: number) => void;
  onChange?: (e: any) => void;
  draggable?: boolean;
  callbackOnSortEnd?: (data: any[]) => void;
  callbackOnUpdateBeforeSortStart?: (data?: any) => void;
  rowKey?: string;
  showCheckAll?: boolean;
}

export interface TableComponents {
  table?: any;
  header?: {
    wrapper?: any;
    row?: any;
    cell?: any;
  };
  body?: {
    wrapper?: any;
    row?: any;
    cell?: any;
  };
}
