import { Layout } from 'antd';
import axios from 'axios';
import {
  CaretDownOutlined,
  Dropdown,
  Icon,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
  Space,
  Spacing,
  UserOutlined,
  Row as CRow,
  Text,
} from 'farmhub-ui-core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState, useAppDispatch, useAppSelector } from 'app';
import {
  Action,
  IconNotification,
  IconWrapper,
  NameText,
  TinyText,
} from 'components';
import { BellIcon } from 'components/Icon';
import { env } from 'env';
import { useTabActive } from 'hooks';
import { logout, updateMenuToggle } from 'slice';
import { ICONS, IMAGETYPE } from 'utils/constants';
import { formatImageUrl, truncate } from 'utils/function';

const { Header } = Layout;

interface HeaderMainNavbarProps {
  user: any;
  onLogoutUser: (e: any) => void;
}

const HeaderMainNavbar = ({
  user,
  onLogoutUser,
}: HeaderMainNavbarProps): JSX.Element => {
  const dispatch = useDispatch();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const isActiveTab = useTabActive();
  const isToggle = useSelector((state: RootState) => state.account.isToggle);
  const [width, setWidth] = React.useState(1200);

  const dropdownItems = [
    <UserInfo>
      <Spacing direction="vertical">
        <NameText lineNumber={2}>{user?.fullName}</NameText>
        <TinyText>{user?.email}</TinyText>
      </Spacing>
    </UserInfo>,
    <Action
      onClick={() => console.log('click')}
      dropdownItemProps={{
        icon: {
          children: (
            <Icon>
              <UserOutlined />
            </Icon>
          ),
        },
        key: '1',
      }}
    >
      Thông tin của tôi
    </Action>,
    <Action
      onClick={onLogoutUser}
      dropdownItemProps={{
        icon: {
          children: (
            <Icon>
              <LogoutOutlined />
            </Icon>
          ),
        },
        key: '2',
      }}
    >
      Thoát tài khoản
    </Action>,
  ];

  const loadRecheckPermission = async () => {
    const action = `user.${undefined}.${`all.all`}.${[
      'root',
      'admin',
      'editor',
    ]}`;

    try {
      if (accessToken) {
        await axios.create({ baseURL: env.hubtech.identityService })({
          url: '/api/v2/users/verify-permission',
          headers: { authorization: `Bearer ${accessToken}` },
          method: 'GET',
          params: { action },
        });
      }
    } catch (err: any) {
      if (err?.message == 'Request failed with status code 401') {
        await dispatch(logout());
        window.location.replace('/');
      }
    }
  };

  React.useEffect(() => {
    loadRecheckPermission();
  }, [isActiveTab]);

  return (
    <Wrapper id="header_main">
      <DivRow>
        <IconWrapper
          displayBackground
          background="#F8F8F8"
          onClick={() => dispatch(updateMenuToggle(!isToggle))}
          size={36}
          icon={
            <Icon>
              {isToggle ? (
                <LeftOutlined style={{ fontSize: 14, color: '#595959' }} />
              ) : (
                <RightOutlined style={{ fontSize: 14, color: '#595959' }} />
              )}
            </Icon>
          }
        />
      </DivRow>
      <DivRow>
        <WrapperNotification onClick={() => console.log('click')}>
          <IconNotification
            icon={
              <Icon
                svgProps={{
                  icon: ICONS.MESSAGE,
                  width: '24px',
                  height: '24px',
                }}
              />
            }
            count={1}
          />
        </WrapperNotification>
        <Space width={12} />
        <WrapperNotification onClick={() => console.log('click')}>
          <IconNotification
            icon={<BellIcon width={24} height={24} />}
            count={1}
          />
        </WrapperNotification>
        <Space width={24} />
        <Dropdown
          arrow
          items={dropdownItems}
          containerId="header_main"
          menu={{ padding: '12px 0px', width: '280px' }}
        >
          <CRow width="fit-content" styles={{ gap: 6, cursor: 'pointer' }}>
            <Avatar src={formatImageUrl(user.avatar, IMAGETYPE.AVATAR.xxs)} />
            {width > 1100 && (
              <CRow width="fit-content" styles={{ gap: 6 }}>
                <Text weight="extraBold" textTransform="capitalize">
                  {truncate(user.displayName, 16)}
                </Text>
                <CaretDownOutlined />
              </CRow>
            )}
          </CRow>
        </Dropdown>
      </DivRow>
    </Wrapper>
  );
};

export default HeaderMainNavbar;

const Wrapper = styled(Header)`
  display: flex;
  height: 68px;
  justify-content: space-between;
  padding: 32px 18px;
  align-items: center;
  background: #ffffff;
`;

const Avatar = styled.img`
  width: 42px;
  height: 42px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
  border-radius: 50%;
  margin-right: 12px;
`;

const DivRow = styled.div`
  display: flex;
  width: auto;
  align-items: center;
`;

const UserInfo = styled.div`
  width: 300px;
  padding: 24px;
  font-weight: ${props => props.theme.font.weight.regular};
  border-bottom: 1px solid #f1f1f1;
`;

const WrapperAvatar = styled.div`
  width: auto;
  height: 52px;
  display: flex;
  align-items: center;
  .name-text {
    font-weight: ${props => props.theme.font.weight.bold};
    font-size: ${props => props.theme.font.size.xs};
    line-height: 22px !important;
    margin-right: 12px;
  }
  cursor: pointer;
`;

const WrapperNotification = styled.div<{ active?: boolean }>`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: ${p => (p.active ? 'rgba(57, 181, 74, 0.1)' : '#f5f5f5')};

  &:hover {
    background: rgba(57, 181, 74, 0.1);
  }

  path {
    fill: ${props =>
      props.active
        ? props.theme.colors.text.main
        : props.theme.colors.text.secondary};
  }

  cursor: pointer;
`;

const InfoAvatar = styled.div`
  display: flex;
  width: 160px;
  height: 42px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
