/* eslint-disable no-useless-catch */
import { identityService } from 'api/services';
import { Credentials } from 'slice';

const pathname = '/users';

export const AuthApi = {
  login: async (credentials: Credentials) => {
    try {
      const response = await identityService.post(
        `${pathname}/login-admin`,
        credentials
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  refreshAccessToken: async () => {
    try {
      const response = await identityService.post(`${pathname}/refresh-token`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  logout: async () => {
    try {
      const response = await identityService.post(`${pathname}/logout`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  logoutAll: async () => {
    try {
      const response = await identityService.post(`${pathname}/logout-all`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
